<template>
  <section v-editable="blok" hero-centered :style="[sectionStyles]" :class="[ `section-style--${blok.style}` ]">
    <div class="container">
      <div class="section-row">
        <div class="section-content">
          <h1 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h1>
          <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
          <div v-if="blok?.buttons?.length > 0" class="section-buttons">
            <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="blok.image_bottom?.filename" :class="{ 'container--fluid': blok.style === 'full-width' }">
      <figure class="section-image" :class="{ 'section-has-mobile': blok.image_bottom_mobile?.filename }">
        <img v-if="blok.image_bottom?.filename" :src="optimizeImage(blok.image_bottom.filename)" :alt="`Hero Image ${blok.image_bottom.alt}`" class="section-image-element" />
        <img v-if="blok.image_bottom_mobile?.filename" :src="optimizeImage(blok.image_bottom_mobile.filename)" :alt="`Hero Image Mobile ${blok.image_bottom_mobile.alt}`" class="section-image-element-mobile" />
      </figure>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/hero-centered.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });
const title = computed(() => renderRichText(props?.blok?.title || {}));
const description = computed(() => renderRichText(props?.blok?.description || {}));

const sectionStyles = computed(() => {
  return {
    "background-image": `url(${props.blok?.background?.filename})`,
  };
});
</script>
